<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">构建工作标准</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        通过活动分解，将工作内容分拆至最小颗粒度，然后制定可测量的工作标准。
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        KPI考核的依据拍脑袋
        <br />
        考核内容难以测量
        <br />
        评价工作绩效太虚
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        培训活动分解的方法
        <br />
        手把手教会
        <br />
        共同检讨分析的内容和标准
        <br />
        通过软件平台进行工作标准的大数据分析，提供优化改进的参考
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>